if (navigator.serviceWorker) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(
        "/service-worker.js"
      )
      .then(() => console.log("Using service worker"))
      .catch((err) => console.log(`Here is the error: ${err}`));
  });
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'

import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-vue';
import store from "./store";

Amplify.configure(awsconfig);
Vue.prototype.$sytAuth = Auth;

Vue.config.productionTip = false;

Vue.use(require('vue-script2'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.push({ name: "Home" });

