import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sytUser: {       //this.$store.state.sytUser.userID
            userID: "",
            userName: "",
            name: "",
            picture: "",
            type: "",
            status: "",
            vendorName: "",
            operatingAsVendor: "", 
            vendorDelegates: []
        },
        loading: false,
        authenticated: false,
        voucher: {
            voucherId: "",
            trackId:"",
            patronId: "",
            rewardId: "",
            vendorId: "",
            createDate: "",
            redeemDate: "",
            status: "",
            vendorName: "",
            rewardName: "",
            actualReward: "",
        },
        rewardToEdit: {
            rewardId: "",
            vendorId: "",
            vendorName: "",
            rewardName: "",
            redeemAmount: "",
            startDate: "",
            endDate: "",
            actualReward: "",
          }
    },
    getters: {},
    mutations: {
        updateUser(state, payload) {
            state.sytUser = payload;     //this.$store.commit("updateUser", sytUser);
        },
        updateUserType(state, payload) {
            state.sytUser.type = payload;     //this.$store.commit("updateUserType", type);
        },
        updateUserVendorName(state, payload) {
            state.sytUser.vendorName = payload;     //this.$store.commit("updateUserVendorName", vendorName);
        },
        setAuthStatus(state, payload) {
            state.authenticated = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setVoucher(state, payload) {
            state.voucher = payload;
        },
        setRewardToEdit(state, payload) {
            state.rewardToEdit = payload;
        },
    },
    actions: {}
});