/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_CBmu0L7kU",
    "aws_user_pools_web_client_id": "3vs4s0njouq9j3enaftbbt8jkp",
    "oauth": {
        "domain": "cu2mr.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        //  "redirectSignIn": "http://localhost:8080/",
        //  "redirectSignOut": "http://localhost:8080/",
       "redirectSignIn": "https://cu2mr.com/",
       "redirectSignOut": "https://cu2mr.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
