import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/Scan.vue')
  },
  {
    path: '/qr',
    name: 'QR',
    component: () => import(/* webpackChunkName: "qr" */ '../views/QR.vue')
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/Rewards.vue')
  },
  {
    path: '/provision',
    name: 'Provision',
    component: () => import(/* webpackChunkName: "provision" */ '../views/Provision.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
