<template>
  <div id="app">
    <div class="containerNav">
      <div class="navPosition">
        <div id="nav">

          <div class="grid-container">
            <div class="item2">
              <div id="logo"><a href="/"><img src="./assets/logo.png" class="imgIcon" alt="See you tomorrow" /></a>
              </div>
            </div>
            <div class="item3">See you tomorrow ~ rewarding rewards</div>
            <div class="item5">
              <router-link to="/home">Home</router-link> |
              <router-link to="/admin">Admin</router-link> |
              <router-link to="/scan">Scan</router-link> |
              <router-link to="/qr">QR</router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  mounted: function () {
    let randumNumber = Math.floor(Math.random() * 9) + 1; // no image called 0.jpg. always add 1 or change image name
    document.body.style.backgroundImage =
      "url(" + require("@/../public/img/bg/" + randumNumber + ".jpg") + ")";



    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data === 'SW_ACTIVATED') {
        window.location.reload();
        // console.log("Window reloaded!!");
      } 
    });
  },
  methods: {},
};
</script>

<style>
html,
body {
  height: 100%;
}

body {
  height: 99%;
  background-color: rgba(248, 245, 240, 0.9);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: auto;
  margin: 0px;
  padding: 0px;
}


.centerOfRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

button {
  border-radius: 6px;
  padding: 5px;
  margin: 5px;
  /* background-color:#f1841f; */
  /* color: rgba(7, 63, 246, 0.753); */
  color: rgb(7, 63, 246);
  border: 1px solid rgb(165, 164, 164);
  /* border: 1px solid#f1841f; */
  font-weight: bold;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
}

#logoContainer {
  /* padding: 15px 20px 15px 60px; */
  background-color: rgba(251, 251, 251, 0.7);
  text-align: center;
  border-radius: 6px;
  margin: 3px;
  width: 100%;
}

#logo {
  position: relative;
  float: left;
  /* top: 8px;
  left: 8px;
  margin-left: auto;
  margin-right: auto;  */
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: rgb(7, 63, 246); */
  color: rgb(13, 13, 14);
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.containerNav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 735px;
  padding: 5px;
  width: 100%;
}

#nav {
  /* padding: 15px 20px 15px 60px; */
  padding: 3px;
  background-color: rgba(251, 251, 251, 0.7);
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin: 3px;
  width: 100%;
}

#nav a {
  padding: 3px 3px 3px 3px;
  font-weight: bold;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  color: rgb(7, 63, 246);
  /* color: rgb(13, 13, 14); */

}

#nav a.router-link-exact-active {
  /* color: #f1841f;
   */
  /* color: rgb(218, 163, 91); */
  /* color: rgb(245, 156, 39); */
  color: rgb(253, 75, 5);
}

.rcornersInput {
  border-radius: 5px;
  border: 1px solid rgb(7, 63, 246);
  padding: 4px;
  margin: 2px;
  width: 80%;
  background-color: rgba(255, 255, 255);
  /* color: rgb(7, 63, 246); */
  color: rgb(13, 13, 14);
  font-size: 1.3em;
}

.imgIcon {
  border-radius: 50%;
  opacity: 0.9;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80px; */
  /* border: 1px solid rgb(131, 131, 131); */
}

.right {
  float: right;
  display: flex;
  position: relative;
  padding: 0 0 0 5px;
  margin: -3px 0 0 0;
  /* border: solid 1px red; */
}

.rightButton {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 100%;
}

.left {
  float: left;
  position: relative;
  padding: 0 0 0 0;
  margin: 3px;
}

.smaller {
  width: 30px;
  height: auto;
  /* border: solid 1px red; */
}

.item {
  background-color: rgba(251, 251, 251, 0.2);
  padding: 5px;
  text-align: left;
  border-radius: 6px;
  border: solid 1px silver;
  margin: 5px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  text-decoration: none;
  color: rgb(0, 0, 145);
}

.lineItem {
  padding: 3px;
  text-align: left;
  margin: 1px;
  /* border: solid 1px red; */
}

.lineItem.show {
  display: none;
}

.lineItemHeader {
  padding: 1px;
  text-align: center;
  margin: 1px;
}

.item1 {
  grid-area: main;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.0em;
  text-decoration: none;
  color: rgb(0, 0, 145);
  /* min-height: 30px; */
}

.item2 {
  grid-area: menu;
  padding: 5px 0 0 0;
}

.item3 {
  grid-area: main;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  text-decoration: none;
  color: rgb(0, 0, 145);
  /* min-height: 30px; */
}

.item5 {
  grid-area: footer;
}


.grid-container {
  display: grid;
  grid-template-areas:
    'menu main main main main main main main main main main main main main main'
    'menu footer footer footer footer footer footer footer footer footer footer footer footer footer footer';
  gap: 1px;
  /* background-color: #2196F3; */
  /* padding: 10px; */
}

.grid-container>div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: center;
}
</style>
